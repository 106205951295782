import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ShutterstockPhotoHomePage1 from '../images/uploads/Homepage_image_1_shutterstock_1756590620.jpg';
import ConvenientIcon from '../images/uploads/icons/icon-convenient.png';
import TransparentIcon from '../images/uploads/icons/icon-transparent.png';
import SavingsIcon from '../images/uploads/icons/icon-savings.png';
import SecureIcon from '../images/uploads/icons/icon-secure.png';
import ShutterstockPhotoHomePage2 from '../images/uploads/Homepage_image_2_shutterstock_1033103851.jpg';

const IndexPage = () => {
  return (
    <Layout pageSlug="home">
      <SEO title="Home" />
      <div className="home-page">
        <section className="row header">
          <div className="row bg-purple header-row">
            <div className="container">
              <div className="row">
                <div className="col s24">
                  <div className="box bs">
                    <div className="header-content">
                      <h1>
                        Simple. Secure.
                        <br />
                        <span>Convenient.</span>
                      </h1>
                      <p>
                        <strong>Payments2study</strong> are specialists in
                        providing secure payment services for international
                        students.
                      </p>
                      <p>
                        <Link
                          className="btn btn-primary animated-arrow"
                          to="/contact-us"
                        >
                          <span className="the-arrow -left">
                            <span className="shaft"></span>
                          </span>
                          <span className="main">
                            <span className="text"> Get help now </span>
                            <span className="the-arrow -right">
                              <span className="shaft"></span>
                            </span>
                          </span>
                        </Link>
                      </p>
                    </div>
                    <div
                      className="
                    bg-plus-grid-bg
                    inner-margin-top-minus-60 inner-margin-bottom-60
                  "
                    >
                      <img src={ShutterstockPhotoHomePage1} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="row bg-grey padding-top-20 padding-bottom-20">
          <div className="container">
            <div className="row align-center">
              <div className="col l6 m12 s24">
                <div className="box bxl icon-box">
                  <img src={ConvenientIcon} alt="" />
                  <div className="text-box">
                    <h3>Convenient</h3>
                    <p>Pay in your own currency</p>
                  </div>
                </div>
              </div>
              <div className="col l6 m12 s24">
                <div className="box bxl icon-box">
                  <img src={TransparentIcon} alt="" />
                  <div className="text-box">
                    <h3>Transparent</h3>
                    <p>Know exactly how much you are paying before you pay</p>
                  </div>
                </div>
              </div>
              <div className="col l6 m12 s24">
                <div className="box bxl icon-box">
                  <img src={SavingsIcon} alt="" />
                  <div className="text-box">
                    <h3>Savings</h3>
                    <p>Better exchange rates and lower fees - cost savings</p>
                  </div>
                </div>
              </div>
              <div className="col l6 m12 s24">
                <div className="box bxl icon-box">
                  <img src={SecureIcon} alt="" />
                  <div className="text-box">
                    <h3>Secure</h3>
                    <p>Fully regulated service provider</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="row plus-grid-bg">
          <div className="container">
            <div className="row padding-top-30 padding-bottom-30">
              <div className="col l12 m12 s24">
                <div className="box bs">
                  <img
                    src={ShutterstockPhotoHomePage2}
                    alt="How does it work"
                  />
                </div>
              </div>
              <div className="col l10 m12 s24">
                <div className="box bs flex-box">
                  <div className="flex-item">
                    <h2>
                      Cheaper.
                      <br />
                      <span>Faster. Easier.</span>
                    </h2>
                    <p>
                      If you are a student needing to pay tuition fees while you
                      study abroad or a school, college or university with
                      international students, we can help. You can pay using
                      bank transfer or debit/credit card for your convenience.
                    </p>
                    <p>
                      We make your international payments cheaper with better
                      currency exchange rates and lower fees than your bank or
                      credit card offer.
                    </p>
                    <p>
                      All transactions take place via our UK FCA registered and
                      regulated partner Exchange4Free for your financial
                      security.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
